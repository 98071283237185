<template>

  <section class="article" v-if="article">


    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Radio Kraków Kultura" class="breadcrumb">
            Radio Kraków Kultura
          </router-link>

          <router-link to="/podcasty" title="Podcasty" class="breadcrumb">
            Podcasty
          </router-link>
          <router-link :to="'/'+article.url" :title="article.title" class="breadcrumb">
            {{ article.title }}
          </router-link>

        </div>
      </div>
      <div class="row">
        <div class="col s12 l8" :class="{loading: stateCompare('loading')}">

          <div class="article__info">

            <h1 class="article__title big-title mb-4">{{ article.title }}</h1>

            <p class="article__lead">{{ article.lead }}</p>

            <ul class="meta">

              <li class="meta__item" v-if="article.review == 'yes'">
                <div class="review-star">
                  <div class="review-star__bg"></div>
                  <div class="review-star__load"
                       :style="{ width: `${(article.review_star / 5)*100}%` }"></div>
                </div>
              </li>

              <li class="meta__item meta__item-fullopacity " v-if="article.user">

                <a :href="article.user.full_url"
                   class="user-item user-item-min">
                  <div class="user-item__thumb" :style="{ backgroundImage: `url(${article.user.avatar_url})` }"></div>
                  <div class="user-item__name">
                    {{ article.user.firstname }}
                    {{ article.user.lastname }}
                  </div>
                  <div class="user-item__position"></div>

                </a>
              </li>


              <li class="meta__item">
                <i class="material-icons">date_range</i>
                {{ article.date }}
              </li>
              <li class="meta__item meta__item-labels meta__item-fullopacity">

                <ul class="tag-list">
                  <li class="tag-list__item" v-for="label in article.labels">{{ label.name }}</li>
                </ul>
              </li>
            </ul>
            <div class="article__thumb" :style="{ backgroundImage: `url(${article.thumb})` }">
              <div class="article__important" v-if="article.is_important">
                Ważne
              </div>
            </div>

            <p class="article__thumb-tags" v-if="article.thumb_tags">
              {{ article.thumb_tags }}
            </p>

            <video class="responsive-video" controls v-if="article.video_url">
              <source :src="article.video_url" type="video/mp4">
            </video>

            <article>
              <div class="article__text" v-html="article.text"></div>

              <div v-for="article_part in article.parts">
                <ShowPart :article_part="article_part"/>
              </div>

              <br>
            </article>

            <div v-if="article.files">
              <br>
              <h3 class="min-title mb-3">
                Pliki
              </h3>

              <div class="file" v-for="file in article.files">
                <a class="file-download right color-text" :href="file.url_download">
                  <i class="material-icons">download</i>
                </a>
                <div class="file-name">
                  {{ file.name }}
                  <span class="grey-text ml-2">({{ file.size }})</span>
                </div>
                <div class="file-size"></div>
              </div>
            </div>


            <div class="player-play mb-4" v-if="article.audio">
              <div class="player-play__thumb"
                   :style="{ backgroundImage: `url(${article.thumb})` }"
              ></div>
              <div class="player-play__info">
                <h3 class="player-play__name">{{ article.title }}</h3>
                <div class="player-play__play">
                  <a :href="article.audio_url"
                     :data-name="article.title"
                     :data-thumb="article.thumb"
                     :data-url="article.url+'?play='+article.id_top"
                     :id="'audio'+article.id_top" class="audio-btn"
                     v-on:click="playAudio"
                  >
                    <i class="material-icons">play_arrow</i>
                  </a>
                </div>
              </div>
            </div>

            <div v-if="article.location">
              <br>
              <h3 class="min-title mb-3">Adres</h3>
              <iframe width="100%" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                      :src="article.location_ifram_url" class="radius"></iframe>
              <ul class="meta-list pt-0">
                <li class="meta__item">
                  <a :href="article.location_google_url" target="_blank">
                    <i class="material-icons">place</i>
                    {{ article.location_name }}
                  </a>
                </li>
              </ul>
            </div>


            <div v-if="article.review == 'yes'">
              <div class="row">
                <div class="col s12 l6">
                  <div v-if="article.review_plus_arr">
                    <br>
                    <h3 class="min-title mb-3">
                      Plusy
                    </h3>
                    <ul class="review-list review-list-plus">
                      <li class="review-list__item" v-for="review_plus in article.review_plus_arr">{{ review_plus }}
                      </li>
                    </ul>
                  </div>

                </div>
                <div class="col s12 l6">

                  <div v-if="article.review_minus_arr">
                    <br>
                    <h3 class="min-title mb-3">
                      Minusy
                    </h3>
                    <ul class="review-list review-list-plus">
                      <li class="review-list__item" v-for="review_minus in article.review_minus_arr">
                        {{ review_minus }}
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>

            <br>
            <div class="article__foot" v-if="article.tags">

              <b>Tagi:</b>

              <ul class="tag-list">

                <li class="tag-list__item" v-for="label in article.labels">{{ label }}</li>

                <li class="tag-list__item" v-for="tag in article.tags">
                  <a href="">
                    {{ tag }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="article__users" v-if="article.users.length > 0">
              <b>Autor:</b>
              <br>
              <UserSmallList :users="article.users"/>

            </div>
            <div class="article__source" v-if="article.sources && article.sources.length > 0">
              <b>Źródło:</b>
              <ul>
                <li v-for="source in article.sources">
                  <u v-if="source.type === 'none' || (source.type === 'text' && source.source === '') ">
                    Radio Kraków Kultura
                  </u>
                  <u v-if="source.type === 'text' ">{{ source.name }}</u>

                  <div class="user-item user-item-min" v-if="source.type == 'user'"
                       style="display: inline-block;">
                    <div class="user-item__thumb"
                         :style="{ backgroundImage: `url(${source.obj.avatar_url})` }"></div>
                    <div class="user-item__name">
                      {{ source.obj.firstname }}
                      {{ source.obj.lastname }}
                    </div>
                  </div>

                </li>
              </ul>
            </div>

          </div>
        </div>

        <div class="col s12 l4">
          <PodcastNewest :in_row="1"/>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <br><br>

          <div class="article__friend" v-if="article.friends && article.friends.length > 0">
            <div class="title-line mb-3">
              <h2>Zobacz także</h2>
            </div>
            <div class="row">
              <div class="col s12 l4" v-for="article in article.friends">
                <PodcastLine :article="article"/>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <br><br><br><br><br><br><br>

  </section>
</template>

<script>
import axios from "@/axios2";
import ShowPart from "@/views/Audio/Part/Part";
import CoreArray from "@/helpers/array.js";
import UserSmallList from "../People/Part/SmallList";
import PodcastNewest from "./Part/PodcastNewest";
import ComponentListStateMachine from "../../Extends/ComponentListStateMachine";
import PodcastLine from "./Part/PodcastLine";

export default {
  name: "PodcastShow",
  extends: ComponentListStateMachine,
  components: {
    PodcastLine,
    PodcastNewest,
    UserSmallList,
    ShowPart
  },
  props: ['slug'],
  data() {
    return {
      'article': null
    }
  },
  methods: {
    playAudio: function (e) {

      e.preventDefault();

      let audioPlayer = this.$root.$refs.audioPlayer;
      let article = this.article;

      audioPlayer.set(article.audio_url, article.top_name, article.top_thumb_url);
      audioPlayer.play();
      audioPlayer.show();

    },


    show: function () {

      axios.get('article/view', { params: { id: this.article.id } })

    },


    refresh: function (slug) {

      slug = slug ? slug : this.slug;

      this.stateUpdate('sendInfo');

      axios
          .get('article/get', {
            params: {
              slug: slug,
              scope: 'id,title,block_thumb,thumb,url,date,slug,lead,labels,video_url,review,review_star,text,parts,audio,audio_url,users,manifest,thumb_tags',
              parts_scope: 'text,audio,audio_url,audio_time,audio_name,video,video_time,top_name,top_thumb_url,type,thumb',
              users_scope: 'account_type,firstname,lastname,name,avatar_url,url_full',
              is_show: true
            }

          })
          .then(response => {

            let data = response.data.data;

            let domain = "https://off.radiokrakow.pl/api/"
            let domain_new = "https://off.radiokrakow.pl/"

            // data = CoreArray.changeDomain(data, 'thumb', domain, domain_new);
            // data = CoreArray.changeDomain(data, 'audio_url', domain, domain_new);

            this.article = data[0];

            this.$root.metaSet(this.article.title, this.article.lead, this.article.thumb);
            this.stateUpdate('sendSuccess');

            this.show();

          })
          .catch(error => {

            this.stateUpdate('sendWrong');
            this.refresh();

          });

    }
  },
  created() {

    this.refresh();

  },
  watch: {
    '$route.params.slug': function (slug) {

      let prefix = 'podcasty';
      // console.log(slug.substr(0, prefix.length))
      //
      // if (slug && slug.substr(0, prefix.length) === prefix)

      this.refresh(slug);
    }
  },

}
</script>

<style scoped lang="scss">

.article {
  &__thumb {
    width: 100%;
    height: 0;
    padding-bottom: 67%;

    overflow: hidden;

    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    //margin-bottom: 25px;
  }
}

</style>